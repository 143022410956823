'use client';

import Rings from '@/components/Rings';
import RollingBanner from '@/components/RollingBanner';
import SanityImage from '@/components/atoms/SanityImage';
import { useParallax } from '@/hooks/useParallax';
import useWindowSize from '@/hooks/useWindowSize';
import { SanityImageType } from '@/types/generic';
import { motion, useScroll } from 'framer-motion';
import { useRef } from 'react';

type HeroEditorialType = {
  banner?: string;
  media?: SanityImageType;
  rings?: boolean;
  paragraph?: string;
};
const HeroEditorial = ({
  banner,
  media,
  rings,
  paragraph,
}: HeroEditorialType) => {
  const { mobileView } = useWindowSize();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ['start 96px', 'end center'],
  });

  const ringsProps = mobileView
    ? {
        leftRingDistanceY: 300,
        rightRingDistanceY: 40,
        leftRingDistanceX: -30,
        rightRingDistanceX: 0,
        leftRingWidth: 132,
        rightRingWidth: 182,
      }
    : {
        leftRingDistanceY: 150,
        rightRingDistanceY: 150,
        leftRingDistanceX: -20,
        rightRingDistanceX: -60,
        leftRingWidth: 394,
        rightRingWidth: 255,
      };

  return (
    <div ref={scrollRef} className="grid-base relative">
      {banner && (
        <RollingBanner
          className="inset-x-0 relative -left-3 lg:-left-10  row-start-1 text-BW-black bg-transparent z-[2]"
          text={banner}
        />
      )}

      {rings && (
        <Rings
          scrollYProgress={scrollYProgress}
          leftRingClass="absolute left-6 lg:left-24 max-lg:top-32"
          rightRingClass="absolute row-span-full max-lg:top-10 lg:col-start-10 justify-self-end"
          {...ringsProps}
        />
      )}
      <motion.div
        style={{ y: useParallax(scrollYProgress, mobileView ? 40 : 30) }}
        className="col-span-4 aspect-[4/5] -top-48 row-start-2 auto-rows-min w-full grid grid-cols-4 relative gap-y-4 gap-x-[inherit] col-start-2 lg:col-start-5 z-[3]"
      >
        {media && (
          <SanityImage
            image={media}
            height={1200}
            width={900}
            className="col-span-full relative z-10 rounded-3xl w-full object-cover"
          />
        )}

        {paragraph && (
          <p className=" col-span-full md:col-span-3 md:col-start-2 lg:col-span-3 lg:col-start-2 pb-6">
            {paragraph}
          </p>
        )}
      </motion.div>
    </div>
  );
};

export default HeroEditorial;
