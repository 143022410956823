import Button from '@/components/atoms/Button';
import { NextArray } from '@grace-studio/graceful-next/types';

type HeroServiceType = {
  anchorLinks?: NextArray<{ title: string; slug: string }>;
};

const HeroService = ({ anchorLinks }: HeroServiceType) => {
  function handleClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    const link = e.currentTarget as HTMLAnchorElement;
    const anchor = link.getAttribute('href')?.split('#')[1];
    document.querySelector(`#${anchor}`)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }

  return (
    <div className="max-lg:contents lg:col-span-4 pt-4 pb-10">
      {anchorLinks && (
        <>
          <span className="text-xl max-lg:pt-4 col-span-2 text-BW-white max-lg:justify-self-end">
            01/0{anchorLinks.length}
          </span>

          <div className="flex flex-wrap flex-1 h-fit border-t border-BW-white-40 pt-4 gap-2 col-span-full lg:col-span-4 lg:col-start-9">
            {anchorLinks.map((link) => (
              <Button
                type="secondary"
                style="light"
                key={link.slug}
                href={`#${link.slug}`}
                onClick={handleClick}
              >
                {link.title}
              </Button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default HeroService;
