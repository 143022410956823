import { imageUrl, sanityFetch } from '@/api';
import Blocks from '@/compositions/blocks';
import HeroBlock from '@/compositions/blocks/HeroBlock';
import { BlockFactory } from '@/factories/blockFactory';
import { BaseBlockProps } from '@/types/block';
import { DynamicZone, Metadata } from '@grace-studio/graceful-next/components';
import { NextArray } from '@grace-studio/graceful-next/types';
import { SanityImageObject } from '@sanity/image-url/lib/types/types';
import type { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import { useEffect, useMemo } from 'react';

type PageType = 'start' | 'parent-page' | 'sub-page' | 'editorial';

type DynamicPageProps = {
  blocks: NextArray<BaseBlockProps>;
  metadata?: {
    title: string;
    description?: string;
    shareImage?: SanityImageObject & { url: string };
  };
  type: PageType;
  slug: { current: string };
  title: string;
  hero: any;
  backgroundColor: string;
  subPages: NextArray<any>;
  preview: boolean;
};

const DynamicPage: NextPage<DynamicPageProps> = ({
  blocks,
  preview,
  hero,
  slug,
  backgroundColor = '#F5F5F0',
  ...props
}) => {
  const anchorLinks = useMemo(() => {
    const showLinks = blocks?.some(
      (block) => block._type === 'service-card-block'
    );
    const links = props.subPages?.map((subPage) => ({
      title: subPage.title,
      slug: subPage.slug.current,
    }));

    return showLinks ? links : false;
  }, [blocks, props.subPages]);

  useEffect(() => {
    const docStyle = document.documentElement.style;
    docStyle.setProperty('--backgroundColor', backgroundColor);
  }, [backgroundColor]);

  return (
    <>
      <Metadata
        metadata={
          props.metadata && {
            ...props.metadata,
            shareImage: props?.metadata?.shareImage
              ? { url: imageUrl(props.metadata.shareImage).width(1200).url() }
              : undefined,
          }
        }
      />
      <HeroBlock
        block={{
          ...hero,
          ...props,
          anchorLinks: anchorLinks,
        }}
        {...props}
      />
      {blocks && (
        <DynamicZone
          blockDefinition={Blocks}
          blocks={BlockFactory.convertName(blocks)}
          {...props}
        />
      )}
      {preview && (
        <a
          href={`/api/exit-preview?slug=${slug.current}`}
          className="fixed bottom-20 right-10 uppercase bg-accent-wine text-BW-white z-50 py-4 px-4"
        >
          Exit Preview
        </a>
      )}
    </>
  );
};

export default DynamicPage;

export const getStaticPaths: GetStaticPaths = async () => {
  const slugs = await sanityFetch('*[_type == "page"]{"slug":slug.current}[]');
  const paths = slugs
    .map(({ slug }) => ({ params: { slug, locale: 'sv' } }))
    .filter(Boolean);

  return {
    paths,
    fallback: false,
  };
};

export const getStaticProps: GetStaticProps<
  DynamicPageProps,
  { slug: string }
> = async (context) => {
  const preview = context.preview || false;
  const slug = context.params!.slug;
  const previewToken = preview ? process.env.SANITY_READ_TOKEN : '';

  const menuQuery =
    '*[_type == "menu" && _id == "menu"]{items[]->{title,"slug":slug.current}, nextWorkout, socials,contactButton}';
  const pageQuery = `*[_type == "page" && slug.current == "${slug}"]{title,slug,blocks,media,metadata,type,backgroundColor,hero,usps,subPages[]->{...}}`;
  const footerQuery = '*[_type == "footer" && _id == "footer"]{banner}';
  const [[menu], [page], [footer]] = await Promise.all([
    sanityFetch(menuQuery, previewToken),
    sanityFetch(pageQuery, previewToken),
    sanityFetch(footerQuery, previewToken),
  ]);

  return {
    props: {
      ...(menu ? { menu } : {}),
      ...(page ? page : {}),
      ...(footer ? { footer } : {}),
      preview,
    },
  };
};
