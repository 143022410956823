import Button from '@/components/atoms/Button';
import { useLinkUrl } from '@/hooks/useLinkUrl';
import { LinkType } from '@/types/generic';
import classNames from 'classnames';
import { motion } from 'framer-motion';

export type ProgressItemProps = {
  title: string;
  link: LinkType;
  active?: boolean;
  image?: any;
  _key?: string;
  number?: string;
};

const ProgressItem = ({ title, link, active, number }: ProgressItemProps) => {
  const { linkUrl } = useLinkUrl(link);

  return (
    <>
      <div className="inline relative z-30 w-full">
        <motion.div
          style={{ opacity: 0.4 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0 }}
          className={classNames(
            active ? 'lg:opacity-100' : 'lg:!opacity-40',
            'flex w-full flex-col gap-3 lg:gap-4 text-BW-white relative hover:!opacity-100 pt-3 lg:pt-4 transition-opacity duration-300'
          )}
        >
          <div className="flex justify-between text-base">
            <div>{title}</div>
            {number && <div className="lg:hidden">{number}</div>}
          </div>
          {link && (
            <Button href={linkUrl} type="secondary" style="light">
              {link.text}
            </Button>
          )}
        </motion.div>
      </div>
    </>
  );
};

export default ProgressItem;
