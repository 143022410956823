'use client';
import ContentWrapper from '@/components/ContentWrapper';
import Scroll from '@/components/atoms/Scroll';
import { BaseBlockComponentProps } from '@/types/block';
import {
  LinkType,
  PageItemType,
  PageTypes,
  SanityImageType,
} from '@/types/generic';
import { MenuProps } from '@/types/menu';
import { NextArray } from '@grace-studio/graceful-next/types';
import classNames from 'classnames';
import HeroService from './HeroService';
import HeroServiceSecondary from './HeroServiceSecondary';
import HeroStart from './HeroStart';
import HeroEditorial from './HeroEditorial';
import SanityImage from '@/components/atoms/SanityImage';

export type HeroBlockProps = BaseBlockComponentProps<{
  title: string;
  type: HeroTypes;
  ingress?: string;
  slides?: NextArray<any>;
  banner?: string;
  media: SanityImageType;
  anchorLinks?: NextArray<PageItemType>;
  menu: MenuProps;
  usps?: NextArray<string>;
  button?: LinkType;
  subheading?: string;
}>;

type HeroTypes = PageTypes;

const HeroBlock: HeroBlockProps = ({ block, ...props }) => {
  const heroClass: Record<HeroTypes, string[]> = {
    start: ['grid-base grid-rows-5 h-screen lg:h-[calc(100vh+5px)]'],
    service: ['h-[92vh] grid grid-rows-2 [&_.content]:max-lg:auto-rows-min'],
    services: [
      'h-[92vh] grid grid-rows-2 [&_.content]:max-lg:grid-rows-[auto_1fr]',
    ],
    editorial: ['pt-24 min-h-screen'],
  };

  return (
    <ContentWrapper
      id="hero"
      className={classNames('relative', heroClass[block.type])}
      marginAfter="none"
    >
      {block.type === 'start' ? (
        <HeroStart {...props} {...block} />
      ) : (
        <>
          {block.type === 'editorial' ? (
            <HeroEditorial {...props} {...block} />
          ) : (
            <div className="px-[inherit] fixed pt-[45vh] inset-0 lg:mb-14 pb-6 lg:pb-20 max-lg:auto-rows-min row-start-2 h-[95vh] col-span-full ">
              {block.media && (
                <>
                  <div
                    aria-hidden
                    className="inset-0 absolute -z-[1] bg-[#000000] opacity-20"
                  />
                  <SanityImage
                    image={block.media}
                    quality={85}
                    priority
                    className="-z-[2] object-cover h-full w-full absolute inset-0"
                  />
                </>
              )}
              <div className="content grid-base gap-y-3 h-full border-t border-BW-white-40">
                <div
                  className={classNames(
                    block.type === 'services' ? 'col-span-full' : 'col-span-4',
                    'lg:col-span-8 flex flex-col justify-between'
                  )}
                >
                  <h1 className="text-xl flex flex-col gap-y-1 lg:gap-y-2 uppercase pt-4 text-BW-white">
                    {block.title}
                    {block.subheading && (
                      <span className="text-base  capitalize font-normal">
                        {block.subheading}
                      </span>
                    )}
                  </h1>
                  <Scroll className="max-lg:hidden self-end text-BW-white z-10 pb-10" />
                </div>
                {block.type === 'service' && (
                  <HeroService {...block} {...props} />
                )}
                {block.type === 'services' && (
                  <HeroServiceSecondary {...block} />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </ContentWrapper>
  );
};

export default HeroBlock;
