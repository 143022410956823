import NextWorkout from '@/components/atoms/NextWorkout';
import Progress from '@/components/atoms/Progress';
import Trademark from '@/components/atoms/Trademark';
import { MenuProps } from '@/types/menu';
import { NextArray } from '@grace-studio/graceful-next/types';

type HeroStartType = {
  title: string;
  menu: MenuProps;
  ingress?: string;
  slides?: NextArray<any>;
};

const HeroStart = ({ title, ingress, slides, menu }: HeroStartType) => (
  <>
    <div className="col-span-6 md:col-span-4 lg:col-span-5 row-start-3 self-center lg:col-start-7 z-10 text-BW-white text-lg relative">
      <h1>
        {title.toLocaleLowerCase().includes('oz—studio') ? (
          <Trademark />
        ) : (
          <span className="uppercase">{title}</span>
        )}
        {ingress && (
          <span className="font-normal">{ingress.split('.')[0]}.</span>
        )}
      </h1>
      {ingress && <span className="font-normal">{ingress.split('.')[1]}.</span>}
      <NextWorkout
        className="font-normal lg:hidden mt-6"
        {...menu.nextWorkout}
      />
    </div>

    {slides && (
      <Progress
        className="row-start-5 col-span-6 w-full flex lg:col-start-7 pb-10"
        items={slides}
      />
    )}
  </>
);

export default HeroStart;
