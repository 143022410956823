import { imageUrl } from '@/api';
import { SanityImageType } from '@/types/generic';
import classNames from 'classnames';
import Image from 'next/image';

type SanityImageProps = {
  image: SanityImageType;
  priority?: boolean;
  width?: number;
  height?: number;
  fill?: boolean;
  sizes?: string;
  className?: string;
  quality?: number;
};

const SanityImage = ({
  image,
  priority = false,
  width = 1400,
  height = 700,
  fill,
  sizes = '(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw',
  className,
  quality = 80,
}: SanityImageProps) => {
  const sanityImageUrl = imageUrl(image).url();

  return (
    <Image
      className={classNames(className)}
      {...(fill ? { fill, sizes } : { width, height })}
      priority={priority}
      quality={quality}
      src={sanityImageUrl}
      alt={image.alternativeText ?? ''}
    />
  );
};
export default SanityImage;
