import { MotionValue, motion } from 'framer-motion';
import Ellipse from '../icons/Ellipse';
import { useParallax } from '@/hooks/useParallax';
import classNames from 'classnames';

type RingsProps = {
  scrollYProgress: MotionValue<number>;
  leftRingDistanceX: number;
  rightRingDistanceX: number;
  leftRingDistanceY: number;
  rightRingDistanceY: number;
  leftRingClass: string;
  rightRingClass: string;
  leftRingWidth?: number;
  rightRingWidth?: number;
};
const spring = {
  type: 'spring',
  mass: 1,
  stiffness: 28.83,
  damping: 12,
};

const Rings = ({
  scrollYProgress,
  leftRingClass,
  rightRingClass,
  leftRingDistanceX,
  rightRingDistanceX,
  leftRingDistanceY,
  rightRingDistanceY,
  leftRingWidth = 395,
  rightRingWidth = 394,
}: RingsProps) => (
  <div className="contents text-accent-wine">
    <motion.div
      initial={{ marginTop: 20, opacity: 0 }}
      animate={{ marginTop: 0, opacity: 1 }}
      className={classNames(leftRingClass, 'w-auto')}
      style={{
        y: useParallax(scrollYProgress, leftRingDistanceY),
        x: useParallax(scrollYProgress, leftRingDistanceX),
      }}
      transition={{ spring, top: { duration: 0.6 } }}
    >
      <Ellipse width={leftRingWidth} className="fill-none" />
    </motion.div>
    <motion.div
      initial={{ marginTop: 40, opacity: 0 }}
      animate={{ marginTop: 0, opacity: 1 }}
      className={classNames(rightRingClass, 'w-auto')}
      style={{
        y: useParallax(scrollYProgress, rightRingDistanceY),
        x: useParallax(scrollYProgress, rightRingDistanceX),
      }}
      transition={{ spring, marginTop: { duration: 0.5 } }}
    >
      <Ellipse width={rightRingWidth} className="fill-none" />
    </motion.div>
  </div>
);
export default Rings;
