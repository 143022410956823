'use client';
import { NextArray } from '@/types/generic';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import ProgressItem, { ProgressItemProps } from './ProgressItem';
import { imageUrl } from '@/api';
import useWindowSize from '@/hooks/useWindowSize';

type ProgressProps = {
  items: NextArray<ProgressItemProps>;
  className?: string;
};

const Progress: FC<ProgressProps> = (props) => {
  const { items, className } = props;
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const activeItem = useRef<HTMLDivElement>(null);
  const { mobileView } = useWindowSize();

  function scrollItem(index: number) {
    const parentElement = activeItem.current?.parentElement;
    parentElement?.scrollBy({
      top: 0,
      left: activeItem.current?.clientWidth
        ? activeItem.current.clientWidth + 8
        : 0,
      behavior: 'smooth',
    });
    if (index === 0) {
      parentElement?.scrollBy({
        top: 0,
        left: -1000,
        behavior: 'smooth',
      });
    }
  }
  const images = useMemo(
    () =>
      items.map((item) => ({
        key: item._key,
        url: `${imageUrl(item.image).url()}?w=1920&h=1440&q=85`,
      })),
    [items]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeSlide >= 0) {
        if (activeSlide < items.length - 1) {
          setActiveSlide(activeSlide + 1);
          scrollItem(activeSlide + 1);
        } else {
          setActiveSlide(0);
          scrollItem(0);
        }
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <div
      className={classNames(
        'items-end justify-end flex flex-col whitespace-nowrap overflow-hidden',
        className
      )}
    >
      <AnimatePresence>
        <motion.div
          key={activeSlide ?? 'empty'}
          className="absolute inset-0"
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url('${images[activeSlide].url}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 1 }}
          transition={{
            duration: 0.4,
          }}
        ></motion.div>
      </AnimatePresence>

      <div className="flex gap-2 lg:gap-4  w-full relative z-30">
        {items.map((item, index) => (
          <div key={item._key} className="bg-BW-white-40 w-full h-[1px]">
            {index === activeSlide && (
              <motion.div
                style={{ width: '0%' }}
                animate={{ width: '100%' }}
                transition={{ duration: 5 }}
                className="h-[1px] bg-BW-white"
              />
            )}
          </div>
        ))}
      </div>
      <div className="flex w-full gap-2 lg:gap-6 max-xl:overflow-x-scroll max-xl:snap-mandatory max-xl:snap-x">
        {items.map((item, index) => (
          <motion.div
            ref={activeItem}
            key={item._key}
            onViewportEnter={() =>
              mobileView ? setActiveSlide(index) : setActiveSlide(0)
            }
            className="snap-center inline-flex max-lg:flex-[1_0_100%] basis-1/4 shrink-0 flex-1 progress-item"
          >
            <ProgressItem
              {...item}
              number={`0${index + 1}/0${items.length}`}
              active={index === activeSlide}
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Progress;
