import dynamic from 'next/dynamic';

const Blocks = {
  HeroBlock: dynamic(() => import('./HeroBlock')),
  AccordionBlock: dynamic(() => import('./AccordionBlock')),
  CtaBlock: dynamic(() => import('./CtaBlock')),
  HugeCtaBlock: dynamic(() => import('./HugeCtaBlock')),
  VideoBlock: dynamic(() => import('./VideoBlock')),
  ListBlock: dynamic(() => import('./ListBlock')),
  RichTextBlock: dynamic(() => import('./RichTextBlock')),
  PreambleBlock: dynamic(() => import('./PreambleBlock')),
  InformationPushBlock: dynamic(() => import('./InformationPushBlock')),
  InspirationBlock: dynamic(() => import('./InspirationBlock')),
  ImageBlock: dynamic(() => import('./ImageBlock')),
  ServiceCardBlock: dynamic(() => import('./ServiceCardBlock')),
  RollingBannerBlock: dynamic(() => import('./RollingBannerBlock')),
};

export default Blocks;
