import { useTranslation } from '@/hooks/useTranslation';
import IconArrow from '@/components/icons/IconArrow';
import classNames from 'classnames';

const ScrollArrows = () => (
  <div className="flex">
    <IconArrow className="w-4 h-4" direction="down" />
    <IconArrow className="w-4 h-4" direction="down" />
  </div>
);

type ScrollProps = {
  className: string;
};
const Scroll = ({ className }: ScrollProps) => {
  const translation = useTranslation();

  return (
    <div
      className={classNames(
        'flex pt-4 px-3  w-full',
        className ?? 'justify-between lg:justify-center items-center'
      )}
    >
      <ScrollArrows />
      <span className="text-small uppercase">
        {translation.get('general.scroll')}
      </span>
      <ScrollArrows />
    </div>
  );
};

export default Scroll;
