import { NextArray } from '@grace-studio/graceful-next/types';

const clearAndUpper = (text: string) => text.replace(/-/, '').toUpperCase();

const toPascalCase = (text: string) =>
  text.replace(/(^\w|-\w)/g, clearAndUpper);

const getBlockName = <T>(block: { _type: string }) =>
  toPascalCase(block._type) as keyof T;

export const BlockFactory = {
  convertName: (blocks: NextArray<object & { _type: string }>) =>
    blocks.map((block) => ({
      ...block,
      blockName: getBlockName(block),
    })),
};
