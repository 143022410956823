import useWindowSize from '@/hooks/useWindowSize';
import classNames from 'classnames';

type EllipseProps = {
  className?: string;
  width?: number;
};
const Ellipse = ({ className, width = 394 }: EllipseProps) => {
  const { screenType } = useWindowSize();

  const strokeWidth: Record<typeof screenType, number> = {
    mobile: 16,
    tablet: 32,
    desktop: 40,
  };

  const ellipseWidths: Record<typeof screenType, number> = {
    mobile: 182,
    tablet: 255,
    desktop: 394,
  };

  const ellipseWidth = width ?? ellipseWidths[screenType];

  return (
    <svg
      className={classNames(className, 'h-auto w-auto')}
      width={ellipseWidth}
      height={ellipseWidth}
      viewBox={`0 0 ${ellipseWidth} ${ellipseWidth}`}
    >
      <circle
        className="stroke-[16px] lg:stroke-[40px]"
        cx={ellipseWidth / 2}
        cy={ellipseWidth / 2}
        r={ellipseWidth / 2 - strokeWidth[screenType] / 2}
        stroke="currentColor"
        strokeWidth={strokeWidth[screenType]}
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};
export default Ellipse;
