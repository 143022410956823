import Button from '@/components/atoms/Button';
import { useLinkUrl } from '@/hooks/useLinkUrl';
import { LinkType } from '@/types/generic';
import { NextArray } from '@grace-studio/graceful-next/types';
import classNames from 'classnames';

type HeroServiceType = {
  usps?: NextArray<string>;
  button?: LinkType;
};

const HeroServiceSecondary = ({ usps, button }: HeroServiceType) => {
  const numbers = ['①', '②', '③', '④'];
  const { linkUrl } = useLinkUrl(button);

  return (
    <div className="max-lg:border-t border-BW-white-40 col-span-full lg:col-span-4 pb-8 flex flex-col justify-between gap-y-8">
      <ol className="grid grid-cols-2 gap-y-6">
        {usps?.map((usp, index) => (
          <li
            className={classNames(
              'text-BW-white pt-4',
              index === 2 || index === 3 ? 'border-t border-BW-white-40' : ''
            )}
            key={usp}
          >
            <div className="text-xl">{numbers[index]}</div>
            <span className="text-base">{usp}</span>
          </li>
        ))}
      </ol>
      {button && (
        <Button
          href={linkUrl}
          className="col-span-full w-full"
          style="light"
          size="big"
        >
          {button.text}
        </Button>
      )}
    </div>
  );
};

export default HeroServiceSecondary;
